import { useState } from 'react';

import { Input } from '~/components/ui/input';
import { toCurrency } from '~/lib/currency';

export interface CurrencyInputProps
  extends React.ComponentProps<typeof Input> {}

/**
 * A controlled <Input /> that formats the value as a currency and
 * shows a dollar sign in front of the input.
 */
export function CurrencyInput({ defaultValue, ...rest }: CurrencyInputProps) {
  const [value, setValue] = useState<number | undefined>(
    defaultValue ? Number(defaultValue) : undefined
  );

  // Prevent key from being spread
  const { key, ...inputProps } = rest;

  return (
    <div className="relative">
      <span className="pointer-events-none absolute inset-y-[1px] left-[1px] flex w-8 items-center justify-center rounded-bl rounded-tl border-r bg-muted font-medium text-muted-foreground">
        $
      </span>
      <Input
        {...inputProps}
        inputMode="numeric"
        value={toCurrency(value).replaceAll('$', '')}
        onChange={(e) => {
          const value = e.target.value
            .replaceAll('$', '')
            .replaceAll(',', '')
            .replaceAll('.', '')
            .replace(/\D/g, '');

          setValue(value ? Number(value) : undefined);
        }}
      />
    </div>
  );
}
