import {
  FieldMetadata,
  FormMetadata,
  useInputControl
} from '@conform-to/react';
import { Fragment, useEffect, useId, useRef } from 'react';

import { Banner } from '~/components/banner';
import { CurrencyInput } from '~/components/currency-input';
import { Checkbox } from '~/components/ui/checkbox';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';
import { RadioGroup, RadioGroupItem } from '~/components/ui/radio-group';
import { Textarea } from '~/components/ui/textarea';
import { usePrevious } from '~/hooks/use-previous';
import { cn } from '~/lib/styles';

interface FieldProps {
  className?: string;
  errors?: string[];
  helpText?: React.ReactNode;
  inputProps: React.ComponentProps<typeof Input>;
  label?: React.ReactNode;
  mask?: string;
  renderLabel?(labelProps: React.ComponentProps<typeof Label>): JSX.Element;
}

export function Field({
  className,
  errors,
  helpText,
  inputProps,
  label,
  mask,
  renderLabel
}: FieldProps) {
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  const helpTextId = helpText ? `${id}-help-text` : undefined;

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {label ? (
        <Label htmlFor={id} variant={errorId ? 'destructive' : 'default'}>
          {label}
        </Label>
      ) : renderLabel ? (
        renderLabel({
          htmlFor: id,
          variant: errorId ? 'destructive' : 'default'
        })
      ) : null}

      {/* Extra div to prevent any injected nodes from password managers messing with the layout */}
      <div>
        <Input
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId || helpTextId}
          mask={mask}
          {...inputProps}
          key={inputProps.key}
        />
      </div>

      {helpTextId && (
        <p
          id={helpTextId}
          className="text-xs font-medium text-muted-foreground"
        >
          {helpText}
        </p>
      )}

      {errorId && <FieldErrors id={errorId} errors={errors} />}
    </div>
  );
}

export function CurrencyField({
  className,
  errors,
  helpText,
  inputProps,
  label
}: FieldProps) {
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  const helpTextId = helpText ? `${id}-help-text` : undefined;

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {label ? (
        <Label htmlFor={id} variant={errorId ? 'destructive' : 'default'}>
          {label}
        </Label>
      ) : null}

      <div>
        <CurrencyInput
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId || helpTextId}
          className="pl-10"
          {...inputProps}
          key={inputProps.key}
        />
      </div>

      {helpTextId && (
        <p
          id={helpTextId}
          className="text-xs font-medium text-muted-foreground"
        >
          {helpText}
        </p>
      )}

      {errorId && <FieldErrors id={errorId} errors={errors} />}
    </div>
  );
}

interface TextareaFieldProps {
  className?: string;
  errors?: string[];
  helpText?: React.ReactNode;
  textareaProps: React.ComponentProps<typeof Textarea>;
  label?: React.ReactNode;
}

export function TextareaField({
  className,
  errors,
  helpText,
  textareaProps,
  label
}: TextareaFieldProps) {
  const fallbackId = useId();
  const id = textareaProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  const helpTextId = helpText ? `${id}-help-text` : undefined;

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {label && (
        <Label htmlFor={id} variant={errorId ? 'destructive' : 'default'}>
          {label}
        </Label>
      )}

      {/* Extra div to prevent any injected nodes from password managers messing with the layout */}
      <div>
        <Textarea
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId || helpTextId}
          {...textareaProps}
          key={textareaProps.key}
        />
      </div>

      {helpTextId && (
        <p
          id={helpTextId}
          className="text-xs font-medium text-muted-foreground"
        >
          {helpText}
        </p>
      )}

      {errorId && <FieldErrors id={errorId} errors={errors} />}
    </div>
  );
}

interface CheckboxFieldProps {
  className?: string;
  errors?: string[];
  helpText?: React.ReactNode;
  checkboxProps: Omit<React.ComponentProps<typeof Checkbox>, 'type'> & {
    name: string;
    form: string;
    value?: string;
  };
  label?: React.ReactNode;
  labelClassName?: string;
}

export function CheckboxField({
  className,
  errors,
  helpText,
  checkboxProps,
  label,
  labelClassName
}: CheckboxFieldProps) {
  const { key, defaultChecked, ...rest } = checkboxProps;
  const checkedValue = checkboxProps.value ?? 'on';
  const input = useInputControl({
    key,
    name: checkboxProps.name,
    formId: checkboxProps.form,
    initialValue: defaultChecked ? checkedValue : undefined
  });
  const fallbackId = useId();
  const id = checkboxProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  const helpTextId = helpText ? `${id}-help-text` : undefined;

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      <div className="flex items-center gap-2">
        <Checkbox
          {...rest}
          id={id}
          name={checkboxProps.name}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId || helpTextId}
          checked={input.value === checkedValue}
          onCheckedChange={(state) => {
            input.change(state.valueOf() ? checkedValue : '');
            checkboxProps.onCheckedChange?.(state);
          }}
          onFocus={(event) => {
            input.focus();
            checkboxProps.onFocus?.(event);
          }}
          onBlur={(event) => {
            input.blur();
            checkboxProps.onBlur?.(event);
          }}
          type="button"
        />
        <Label
          htmlFor={id}
          variant={errorId ? 'destructive' : 'default'}
          className={cn('peer-disabled:opacity-50', labelClassName)}
        >
          {label}
        </Label>
      </div>

      {helpTextId && (
        <p
          id={helpTextId}
          className="text-xs font-medium text-muted-foreground"
        >
          {helpText}
        </p>
      )}

      {errorId && <FieldErrors id={errorId} errors={errors} />}
    </div>
  );
}

interface RadioGroupOption {
  label?: React.ReactNode;
  value: string;
}

interface RadioGroupFieldProps {
  className?: string;
  direction?: 'row' | 'column';
  errors?: string[];
  helpText?: React.ReactNode;
  label?: React.ReactNode;
  meta: FieldMetadata<string>;
  options: Array<RadioGroupOption>;
  customOption?: (props: {
    option: RadioGroupOption;
    labelProps: React.ComponentProps<typeof Label>;
    radioGroupItemProps: React.ComponentProps<typeof RadioGroupItem>;
  }) => JSX.Element;
}

/**
 * This one has slightly different props; it accepts the form.field rather than getInputProps()
 * https://codesandbox.io/p/devbox/zealous-golick-586275
 *
 * It can also render a custom option component for more complex radio group items
 */
export function RadioGroupField({
  className,
  direction = 'column',
  label,
  options,
  errors,
  helpText,
  meta,
  customOption
}: RadioGroupFieldProps) {
  const fallbackId = useId();
  const id = meta.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  const helpTextId = helpText ? `${id}-help-text` : undefined;

  const radioGroupRef = useRef<React.ElementRef<typeof RadioGroup>>(null);
  const input = useInputControl(meta);

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {label && (
        <Label asChild variant={errorId ? 'destructive' : 'default'}>
          <div>{label}</div>
        </Label>
      )}

      <div>
        <input
          name={meta.name}
          defaultValue={meta.initialValue}
          tabIndex={-1}
          className="sr-only"
          onFocus={() => {
            radioGroupRef.current?.focus();
          }}
          onBlur={() => {
            radioGroupRef.current?.blur();
          }}
        />
        <RadioGroup
          ref={radioGroupRef}
          value={input.value}
          onValueChange={input.change}
          onBlur={input.blur}
          key={meta.key}
          className={cn(direction === 'row' && 'flex flex-wrap gap-6')}
        >
          {options?.map((option) => {
            if (customOption) {
              return (
                <Fragment key={option.value}>
                  {customOption({
                    option,
                    labelProps: { htmlFor: `${meta.id}-${option.value}` },
                    radioGroupItemProps: {
                      value: option.value,
                      id: `${meta.id}-${option.value}`
                    }
                  })}
                </Fragment>
              );
            } else {
              return (
                <div key={option.value} className="flex items-center gap-2">
                  <RadioGroupItem
                    value={option.value}
                    id={`${meta.id}-${option.value}`}
                  />
                  <Label htmlFor={`${meta.id}-${option.value}`}>
                    {option.label ?? option.value}
                  </Label>
                </div>
              );
            }
          })}
        </RadioGroup>
      </div>

      {helpTextId && (
        <p
          id={helpTextId}
          className="text-xs font-medium text-muted-foreground"
        >
          {helpText}
        </p>
      )}

      {errorId && <FieldErrors id={errorId} errors={errors} />}
    </div>
  );
}

interface SelectFieldProps {
  className?: string;
  errors?: string[];
  helpText?: React.ReactNode;
  label?: React.ReactNode;
  options?: Array<{
    label: React.ReactNode;
    value: string;
    disabled?: boolean;
  }>;
  selectProps: React.ComponentProps<'select'>;
}

export function SelectField({
  className,
  label,
  options,
  errors,
  helpText,
  selectProps
}: SelectFieldProps) {
  const fallbackId = useId();
  const id = selectProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  const helpTextId = helpText ? `${id}-help-text` : undefined;

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      <Label htmlFor={id} variant={errorId ? 'destructive' : 'default'}>
        {label}
      </Label>
      <select
        {...selectProps}
        key={selectProps.key}
        className={cn(
          'flex h-11 w-full rounded-md border border-input bg-background px-3 py-2 pr-9 ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          selectProps.className
        )}
      >
        {options?.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>

      {helpTextId && (
        <p
          id={helpTextId}
          className="text-xs font-medium text-muted-foreground"
        >
          {helpText}
        </p>
      )}

      {errorId && <FieldErrors id={errorId} errors={errors} />}
    </div>
  );
}

interface FormErrorsProps {
  form?: FormMetadata<any, string[]>;
  formErrors?: string[];
}

export function FormErrors({ form }: FormErrorsProps) {
  const id = useId();
  const prevErrors = usePrevious(form?.errors);

  useEffect(() => {
    if (form?.errors?.length && !prevErrors?.length) {
      setTimeout(() => {
        document.getElementById(id)?.scrollIntoView({
          block: 'end'
        });
      }, 0);
    }
  }, [form?.errors?.length, id, prevErrors?.length]);

  if (!form?.errors?.length) {
    return null;
  }

  return (
    <Banner variant="destructive" id={id}>
      <strong className="text-destructive">Error</strong>
      <ul className="list-disc pl-4">
        {form.errors.map((error) => (
          <li key={error} className="text-sm">
            {error}
          </li>
        ))}
      </ul>
    </Banner>
  );
}

interface FieldErrorsProps {
  id: string;
  errors?: string[];
}

export function FieldErrors({ id, errors }: FieldErrorsProps) {
  if (!errors?.length) return null;

  return (
    <ul id={id} className="flex flex-col gap-1">
      {errors.map((e) => (
        <li key={e} className="text-xs font-medium text-destructive">
          {e}
        </li>
      ))}
    </ul>
  );
}
